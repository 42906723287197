import Vue from 'vue';

/**
 * Tailwind
 */
import VueTailwind from 'vue-tailwind';
import { RealyncTailwindComponents } from '@/resources/tailwind';

/**
 * Directives
 */
import { directive as onClickOutside } from 'vue-on-click-outside';
import VueClipboard from 'vue-clipboard2';

/**
 * Components
 */
import FloatingVue from 'floating-vue';
import Notifications from 'vue-notification';
import VueSocialSharing from 'vue-social-sharing';

/**
 * Libraries
 */
import VueFusionCharts from 'vue-fusioncharts';
import FusionCharts from 'fusioncharts';
import Column2D from 'fusioncharts/fusioncharts.charts';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import TimeSeries from 'fusioncharts/fusioncharts.timeseries';

/**
 * Directives enable
 */
Vue.directive('on-click-outside', onClickOutside);
VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);

/**
 * Components enable
 */
Vue.use(FloatingVue, {
  themes: {
    realync: {
      $resetCss: true,
      triggers: ['hover'],
      autoHide: true,
      placement: 'bottom',
      strategy: 'absolute',
      distance: 6,
      skidding: 0,
      html: true,
      autoBoundaryMaxSize: true,
      flip: true
    },
    'realync-dark': {
      $extend: 'realync'
    },
    'realync-dropdown': {
      $resetCss: true,
      autoHide: true,
      triggers: ['click'],
      placement: 'bottom',
      strategy: 'absolute',
      distance: 5,
      skidding: 0,
      html: true,
      autoBoundaryMaxSize: true,
      flip: true
    },
    'realync-select': {
      $resetCss: true,
      autoHide: true,
      triggers: ['click'],
      placement: 'bottom',
      strategy: 'absolute',
      distance: 3,
      skidding: 0,
      html: true,
      autoBoundaryMaxSize: true,
      flip: true
    }
  }
});
Vue.use(Notifications);
Vue.use(VueSocialSharing);

/**
 * Libraries enable
 */
Vue.use(VueFusionCharts, FusionCharts, TimeSeries, Column2D, FusionTheme);

/**
 * Tailwind enable
 */
Vue.use(VueTailwind, RealyncTailwindComponents);
