import {
  TInput,
  TTextarea,
  TSelect,
  TRadio,
  TCheckbox,
  TButton,
  TInputGroup,
  TRichSelect,
  TDatepicker,
  TTag,
  TToggle,
  TAlert,
  TCard,
  TModal,
  TDialog,
  TTable,
  TPagination,
  TDropdown
} from 'vue-tailwind/dist/components';

const buttonConfig = {
  fixedClasses: 'block transition duration-100 ease-in-out focus:ring-1 focus:ring-blue-400 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed',
  classes: 'px-4 py-1.5 text-lg text-white bg-blue rounded-xlg shadow-sm hover:bg-blue-400',
  variants: {
    primary: 'font-semibold px-4 py-1.5 text-lg text-white bg-blue border border-transparent rounded-xlg shadow-sm hover:bg-blue-400',
    white: 'font-semibold px-4 py-1.5 text-lg text-dark bg-white border border-grey rounded-xlg shadow-sm hover:shadow-lg hover:bg-grey/10',
    'primary-rounded': 'font-semibold text-white bg-blue border border-transparent rounded-full shadow-sm hover:bg-blue-400',
    'primary-integration': 'font-semibold px-4 py-1.5 text-lg text-white border border-transparent rounded-xlg shadow-sm',
    outline: 'font-semibold text-blue bg-blue-100 border-2 border-blue-300 rounded-xlg shadow-sm hover:border-transparent hover:bg-blue-200',
    'outline-white': 'font-semibold text-white bg-white-100 px-4 py-1.5 border-2 border-white-300 rounded-xlg shadow-sm hover:bg-white-200',
    'outline-grey': 'font-semibold text-dark-400 bg-dark-200 px-4 py-1.5 border-2 border-dark-300 rounded-xlg shadow-sm hover:bg-dark-200',
    'outline-rounded': 'font-semibold text-blue bg-blue-100 border-2 border-blue-300 rounded-full shadow-sm hover:border-transparent hover:bg-blue-200',
    secondary: 'font-semibold px-4 py-1.5 text-lg text-gray-800 bg-white border border-gray-300 rounded-lg shadow-sm hover:text-gray-600 focus:ring-2 focus:ring-blue focus:ring-opacity-50',
    error: 'font-semibold px-4 py-1.5 text-lg text-white bg-red border border-transparent shadow-sm hover:bg-red-500 focus:ring-2 focus:ring-blue focus:ring-opacity-50',
    'light-blue': 'font-semibold px-4 py-1.5 text-lg text-blue bg-blue-100 border border-transparent rounded-lg shadow-sm hover:bg-blue-200 focus:ring-2 focus:ring-blue focus:ring-opacity-50',
    danger: 'font-semibold px-4 py-1.5 text-lg text-white bg-red border border-transparent rounded-xlg shadow-sm hover:bg-red-500',
    success: 'font-semibold px-4 py-1.5 text-lg text-white bg-green-500 border border-transparent shadow-sm hover:bg-green-600 focus:ring-2 focus:ring-blue focus:ring-opacity-50',
    link: 'inline px-0 py-0 text-blue hover:underline hover:text-blue-400 focus:ring-2 focus:ring-blue focus:ring-opacity-50',
    unstyled: 'inline-block pt-0 pr-0 pb-0 pl-0 border-none focus:ring-2 focus:ring-blue focus:ring-opacity-50',
    'show-more': 'px-4 py-1.5 text-lg flex w-full items-center rounded-lg justify-center text-center text-blue hover:text-blue-400 focus:!ring-0',
    'dropdown-button': 'font-normal px-4 py-1.5 text-lg block w-full rounded-lg text-lg text-left text-dark hover:bg-blue-100 focus:!ring-0 focus:bg-blue-100'
  }
};

export const RealyncTailwindComponents = {
  't-input': {
    component: TInput,
    props: {
      fixedClasses: 'block w-full px-3 py-2 transition duration-100 ease-in-out rounded-lg placeholder-dark-400 hover:border-dark-400 focus:border-dark-400 focus:ring focus:ring-focus-input-ring focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed',
      classes: 'text-dark bg-white border-dark-300 disabled:bg-dark-200 disabled:border-dark-200/0 hover:disabled:border-dark-200/0',
      variants: {
        danger: 'border-red bg-white',
        success: 'border-green bg-white',
        'icon-left': 'pl-9 text-dark bg-white border-dark-300 disabled:bg-dark-200'
      }
    }
  },
  't-textarea': {
    component: TTextarea,
    props: {
      fixedClasses: 'block w-full px-3 py-2 transition duration-100 ease-in-out rounded-lg placeholder-dark-400 hover:border-dark-400 focus:border-dark-400 focus:ring focus:ring-focus-input-ring focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed disabled:border-none',
      classes: 'text-dark border-dark-300 placeholder-gray-400 bg-white border-gray-300 focus:border-blue-400 ',
      variants: {
        danger: 'border-red',
        success: 'border-green-300 bg-green-50 placeholder-gray-400 text-green-900'
      }
    }
  },
  't-select': {
    component: TSelect,
    props: {
      classes: 'block w-full pl-2 pr-4 py-1 border-none transition duration-100 ease-in-out rounded text-xs focus:ring-2 focus:ring-blue-400 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed text-dark placeholder-gray-400 bg-white focus:border-blue-400 ',
      variants: {
        danger: 'border-red-300 bg-red-50 placeholder-red-200 text-red-900',
        success: 'border-green-300 bg-green-50 placeholder-gray-400 text-green-900'
      }
    }
  },
  't-radio': {
    component: TRadio,
    props: {
      wrapped: true,
      classes: {
        input: 'transition duration-100 ease-in-out rounded-full text-dark border-2 focus:border-dark-400 focus:ring-0 focus:outline-none focus:ring-opacity-50 focus:ring-offset-0 disabled:opacity-50 disabled:cursor-not-allowed hover:cursor-pointer',
        inputWrapper: 'inline-flex items-center leading-none mr-2',
        inputWrapperChecked: 'inline-flex items-center leading-none mr-2',
        label: 'max-w-[90%] select-none text-sm truncate hover:cursor-pointer',
        wrapper: 'flex items-center'
      },
      variants: {
        blue: {
          input: 'transition duration-100 ease-in-out rounded-full text-blue border-blue border-2 focus:border-blue-400 focus:ring-0 focus:outline-none focus:ring-opacity-50 focus:ring-offset-0 disabled:opacity-50 disabled:cursor-not-allowed hover:cursor-pointer',
          inputWrapper: 'inline-flex items-center leading-none pr-2',
          inputWrapperChecked: 'inline-flex items-center leading-none pr-2',
          label: 'max-w-[90%] select-none text-sm text-blue truncate hover:cursor-pointer',
          wrapper: 'flex items-center'
        }
      }
    }
  },
  't-checkbox': {
    component: TCheckbox,
    props: {
      wrapped: true,
      classes: {
        input: 'transition duration-100 ease-in-out rounded-full text-dark border-2 focus:border-dark-400 focus:ring-0 focus:outline-none focus:ring-opacity-50 focus:ring-offset-0 disabled:opacity-50 disabled:cursor-not-allowed hover:cursor-pointer',
        inputWrapper: 'inline-flex items-center leading-none mr-2',
        inputWrapperChecked: 'inline-flex items-center leading-none mr-2',
        label: 'flex max-w-[90%] select-none text-sm text-dark truncate hover:cursor-pointer',
        wrapper: 'flex items-center'
      },
      variants: {
        blue: {
          input: 'transition duration-100 ease-in-out rounded-full text-blue border-blue border-2 focus:border-blue-400 focus:ring-0 focus:outline-none focus:ring-opacity-50 focus:ring-offset-0 disabled:opacity-50 disabled:cursor-not-allowed hover:cursor-pointer',
          inputWrapper: 'inline-flex items-center leading-none pr-2',
          inputWrapperChecked: 'inline-flex items-center leading-none pr-2',
          label: 'max-w-[90%] select-none text-sm text-blue truncate hover:cursor-pointer',
          wrapper: 'flex items-center'
        }
      }
    }
  },
  't-button': {
    component: TButton,
    props: {
      type: 'button',
      ...buttonConfig
    }
  },
  't-button-group': {
    component: TTag,
    props: {
      fixedClasses: '',
      classes: ''
    }
  },
  't-submit': {
    component: TButton,
    props: {
      type: 'submit',
      fixedClasses: 'block transition duration-100 ease-in-out focus:ring-2 focus:ring-blue-400 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed',
      classes: 'px-4 py-1.5 text-lg text-white bg-blue rounded-xlg shadow-sm hover:bg-blue-400',
      variants: {
        primary: 'font-semibold px-4 py-1.5 text-lg text-white bg-blue border border-transparent rounded-xlg shadow-sm hover:bg-blue-400',
        'primary-rounded': 'font-semibold text-white bg-blue border border-transparent rounded-full shadow-sm hover:bg-blue-400',
        outline: 'font-semibold text-blue bg-blue-100 border-2 border-blue-300 rounded-xlg shadow-sm hover:border-transparent hover:bg-blue-200',
        'outline-rounded': 'font-semibold text-blue bg-blue-100 border-2 border-blue-300 rounded-full shadow-sm hover:border-transparent hover:bg-blue-200',
        secondary: 'font-semibold px-4 py-1.5 text-lg text-gray-800 bg-white border border-gray-300 rounded-lg shadow-sm hover:text-gray-600 focus:ring-2 focus:ring-blue focus:ring-opacity-50',
        danger: 'font-semibold px-4 py-1.5 text-lg text-white bg-red border border-transparent rounded-xlg shadow-sm hover:bg-red-400'
      }
    }
  },
  't-input-group': {
    component: TInputGroup,
    props: {
      fixedClasses: {
        wrapper: 'relative',
        label: 'block mb-1.5 text-dark text-xs',
        body: 'w-full relative mb-2',
        feedback: 'absolute -bottom-4 left-0 text-xs',
        description: 'text-dark-400 text-xs'
      },
      classes: {
        wrapper: '',
        label: '',
        body: '',
        feedback: 'text-gray-400',
        description: ''
      },
      variants: {
        danger: {
          feedback: 'text-red'
        },
        success: {
          label: 'text-green',
          feedback: 'text-green'
        },
        search: {
          wrapper: 'relative',
          label: 'block',
          body: '',
          feedback: 'absolute -bottom-4 left-0 text-xs',
          description: 'text-gray-400 text-xs'
        }
      }
    }
  },
  't-form': {
    component: TTag,
    tagName: 'form',
    props: {
      fixedClasses: '',
      classes: ''
    }
  },
  't-rich-select': {
    component: TRichSelect,
    props: {
      fixedClasses: {
        wrapper: 'relative',
        buttonWrapper: 'inline-block relative w-full',
        selectButton: 'w-full flex text-left justify-between items-center px-3 py-2 text-dark transition duration-100 ease-in-out border rounded shadow-sm focus:border-blue-400 focus:ring-2 focus:ring-blue-400 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed',
        selectButtonLabel: 'block truncate',
        selectButtonPlaceholder: 'block truncate',
        selectButtonIcon: 'fill-current flex-shrink-0 ml-1 h-4 w-4',
        selectButtonClearButton: 'rounded flex flex-shrink-0 items-center justify-center absolute right-0 top-0 m-2 h-6 w-6 transition duration-100 ease-in-out',
        selectButtonClearIcon: 'fill-current h-3 w-3',
        dropdown: 'absolute w-full z-10 -mt-1 absolute border-b border-l border-r rounded-b shadow-sm z-10',
        dropdownFeedback: '',
        loadingMoreResults: '',
        optionsList: 'overflow-auto',
        searchWrapper: 'inline-block w-full',
        searchBox: 'inline-block w-full',
        optgroup: '',
        option: 'cursor-pointer',
        disabledOption: 'opacity-50 cursor-not-allowed',
        highlightedOption: 'cursor-pointer',
        selectedOption: 'cursor-pointer',
        selectedHighlightedOption: 'cursor-pointer',
        optionContent: '',
        optionLabel: 'truncate block',
        selectedIcon: 'fill-current h-4 w-4',
        enterClass: '',
        enterActiveClass: '',
        enterToClass: '',
        leaveClass: '',
        leaveActiveClass: '',
        leaveToClass: ''
      },
      classes: {
        wrapper: '',
        buttonWrapper: '',
        selectButton: 'bg-white border-gray-300',
        selectButtonLabel: '',
        selectButtonPlaceholder: 'text-gray-400',
        selectButtonIcon: 'text-gray-600',
        selectButtonClearButton: 'hover:bg-blue-100 text-gray-600',
        selectButtonClearIcon: '',
        dropdown: 'bg-white border-gray-300',
        dropdownFeedback: 'pb-2 px-3 text-gray-400 text-sm',
        loadingMoreResults: 'pb-2 px-3 text-gray-400 text-sm',
        optionsList: '',
        searchWrapper: 'p-2 placeholder-gray-400',
        searchBox: 'px-3 py-2 bg-gray-50 text-sm rounded border focus:outline-none focus:shadow-outline border-gray-300',
        optgroup: 'text-gray-400 uppercase text-xs py-1 px-2 font-semibold',
        option: '',
        disabledOption: '',
        highlightedOption: 'bg-blue-100',
        selectedOption: 'font-semibold bg-gray-100 bg-blue-400 font-semibold text-white',
        selectedHighlightedOption: 'font-semibold bg-gray-100 bg-blue-600 font-semibold text-white',
        optionContent: 'flex justify-between items-center px-3 py-2',
        optionLabel: '',
        selectedIcon: '',
        enterClass: 'opacity-0',
        enterActiveClass: 'transition ease-out duration-100',
        enterToClass: 'opacity-100',
        leaveClass: 'opacity-100',
        leaveActiveClass: 'transition ease-in duration-75',
        leaveToClass: 'opacity-0'
      },
      variants: {
        danger: {
          selectButton: 'border-red-300 bg-red-50 text-red-900',
          selectButtonPlaceholder: 'text-red-200',
          selectButtonIcon: 'text-red-500',
          selectButtonClearButton: 'hover:bg-red-200 text-red-500',
          dropdown: 'bg-red-50 border-red-300'
        },
        success: {
          selectButton: 'border-green-300 bg-green-50 text-green-900',
          selectButtonIcon: 'text-green-500',
          selectButtonClearButton: 'hover:bg-green-200 text-green-500',
          dropdown: 'bg-green-50 border-green-300'
        }
      }
    }
  },
  't-datepicker': {
    component: TDatepicker,
    props: {
      fixedClasses: {
        navigator: 'flex',
        navigatorViewButton: 'flex items-center',
        navigatorViewButtonIcon: 'flex-shrink-0 h-5 w-5',
        navigatorViewButtonBackIcon: 'flex-shrink-0 h-5 w-5',
        navigatorLabel: 'flex items-center py-1',
        navigatorPrevButtonIcon: 'h-6 w-6 inline-flex',
        navigatorNextButtonIcon: 'h-6 w-6 inline-flex',
        inputWrapper: 'relative datepicker-input',
        viewGroup: 'inline-flex flex-wrap',
        view: 'w-64',
        calendarDaysWrapper: 'grid grid-cols-7',
        calendarHeaderWrapper: 'grid grid-cols-7',
        monthWrapper: 'grid grid-cols-4',
        yearWrapper: 'grid grid-cols-4',
        input: 'block w-full px-3 py-2 transition duration-100 ease-in-out border rounded shadow-sm hover:cursor-pointer hover:border-dark-400 focus:border-dark-400 focus:ring focus:ring-focus-input-ring focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed',
        clearButton: 'flex flex-shrink-0 items-center justify-center absolute right-0 top-0 m-2 h-6 w-6',
        clearButtonIcon: 'fill-current h-3 w-3',
        // Time selector
        timepickerWrapper: 'flex items-center px-4 py-2',
        timepickerTimeWrapper: 'flex items-center space-x-2',
        timepickerTimeFieldsWrapper: 'bg-gray-100 rounded-md w-full text-right flex items-center border border-gray-100',
        timepickerOkButton: 'ml-2 px-2 py-1 bg-blue text-white text-sm uppercase font-semibold transition duration-100 ease-in-out border border-transparent rounded cursor-pointer',
        timepickerInput: 'text-center w-10 border-transparent bg-transparent p-0 h-8 text-lg transition duration-100 ease-in-out border border-transparent rounded',
        timepickerTimeLabel: 'hidden flex-grow text-lg text-gray-500',
        timepickerAmPmWrapper: 'relative inline-flex flex-shrink-0 transition duration-200 ease-in-out bg-gray-100 border border-transparent rounded cursor-pointer',
        timepickerAmPmWrapperChecked: 'relative inline-flex flex-shrink-0 transition duration-200 ease-in-out bg-gray-100 border border-transparent rounded cursor-pointer',
        timepickerAmPmWrapperDisabled: 'relative inline-flex flex-shrink-0 transition duration-200 ease-in-out opacity-50 cursor-not-allowed',
        timepickerAmPmWrapperCheckedDisabled: 'relative inline-flex flex-shrink-0 transition duration-200 ease-in-out opacity-50 cursor-not-allowed',
        timepickerAmPmButton: 'absolute flex items-center justify-center w-10 h-8 text-lg text-transparent transition duration-200 ease-in-out transform translate-x-0 bg-dark-300 rounded shadow',
        timepickerAmPmButtonChecked: 'absolute flex items-center justify-center w-10 h-8 text-lg text-transparent transition duration-200 ease-in-out transform translate-x-full bg-dark-300 rounded shadow',
        timepickerAmPmCheckedPlaceholder: 'flex items-center justify-center w-10 h-8 text-lg text-gray-500 rounded-sm',
        timepickerAmPmUncheckedPlaceholder: 'flex items-center justify-center w-10 h-8 text-lg text-gray-500 rounded-sm'
      },
      classes: {
        wrapper: 'flex flex-col',
        dropdownWrapper: 'relative z-10',
        dropdown: 'origin-top-left absolute rounded shadow bg-white overflow-hidden mt-1',
        enterClass: 'opacity-0 scale-95',
        enterActiveClass: 'transition transform ease-out duration-100',
        enterToClass: 'opacity-100 scale-100',
        leaveClass: 'opacity-100 scale-100',
        leaveActiveClass: 'transition transform ease-in duration-75',
        leaveToClass: 'opacity-0 scale-95',
        inlineWrapper: '',
        inlineViews: 'rounded bg-white border mt-1 inline-flex',
        inputWrapper: '',
        input: 'text-dark placeholder-dark border-dark-300 disabled:bg-dark-200',
        clearButton: 'hover:bg-gray-100 rounded transition duration-100 ease-in-out text-gray-600',
        clearButtonIcon: '',
        viewGroup: '',
        view: '',
        navigator: 'pt-2 px-3',
        navigatorViewButton: 'transition ease-in-out duration-100 inline-flex cursor-pointer rounded-full px-2 py-1 -ml-1 hover:bg-gray-100',
        navigatorViewButtonIcon: 'fill-current text-gray-400',
        navigatorViewButtonBackIcon: 'fill-current text-gray-400',
        navigatorViewButtonMonth: 'text-gray-700 font-semibold',
        navigatorViewButtonYear: 'text-gray-500 ml-1',
        navigatorViewButtonYearRange: 'text-gray-500 ml-1',
        navigatorLabel: 'py-1',
        navigatorLabelMonth: 'text-gray-700 font-semibold',
        navigatorLabelYear: 'text-gray-500 ml-1',
        navigatorPrevButton: 'transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-100 rounded-full p-1 ml-2 ml-auto disabled:opacity-50 disabled:cursor-not-allowed',
        navigatorNextButton: 'transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-100 rounded-full p-1 -mr-1 disabled:opacity-50 disabled:cursor-not-allowed',
        navigatorPrevButtonIcon: 'text-gray-400',
        navigatorNextButtonIcon: 'text-gray-400',
        calendarWrapper: 'px-3 pt-2',
        calendarHeaderWrapper: '',
        calendarHeaderWeekDay: 'uppercase text-1xs text-gray-500 w-8 h-8 flex items-center justify-center',
        calendarDaysWrapper: '',
        calendarDaysDayWrapper: 'w-full h-8 flex flex-shrink-0 items-center',
        otherMonthDay: 'text-sm rounded-full w-8 h-8 mx-auto hover:bg-blue-100 text-gray-400 disabled:opacity-50 disabled:cursor-not-allowed',
        emptyDay: '',
        inRangeFirstDay: 'text-sm bg-blue-400 text-white w-full h-8 rounded-l-full',
        inRangeLastDay: 'text-sm bg-blue-400 text-white w-full h-8 rounded-r-full',
        inRangeDay: 'text-sm bg-blue-200 w-full h-8 disabled:opacity-50 disabled:cursor-not-allowed',
        selectedDay: 'text-sm rounded w-8 h-8 mx-auto bg-blue text-white disabled:opacity-50 disabled:cursor-not-allowed',
        activeDay: 'text-sm rounded bg-blue-100 w-8 h-8 mx-auto disabled:opacity-50 disabled:cursor-not-allowed',
        highlightedDay: 'text-sm rounded bg-blue-200 w-8 h-8 mx-auto disabled:opacity-50 disabled:cursor-not-allowed',
        day: 'text-sm rounded w-8 h-8 mx-auto hover:bg-blue-100 disabled:opacity-50 disabled:cursor-not-allowed',
        today: 'text-sm rounded w-8 h-8 mx-auto hover:bg-blue-100 disabled:opacity-50 disabled:cursor-not-allowed border border-blue-400',
        monthWrapper: 'px-3 pt-2',
        selectedMonth: 'text-sm rounded w-full h-12 mx-auto bg-blue-400 text-white',
        activeMonth: 'text-sm rounded w-full h-12 mx-auto bg-blue-100',
        month: 'text-sm rounded w-full h-12 mx-auto hover:bg-blue-100',
        yearWrapper: 'px-3 pt-2',
        year: 'text-sm rounded w-full h-12 mx-auto hover:bg-blue-100',
        selectedYear: 'text-sm rounded w-full h-12 mx-auto bg-blue-400 text-white',
        activeYear: 'text-sm rounded w-full h-12 mx-auto bg-blue-100'
      },
      variants: {
        danger: {
          input: 'border-red bg-white',
          clearButton: 'hover:bg-red-200 text-red-500'
        }
      }
    }
  },
  't-tag': {
    component: TTag,
    props: {
      fixedClasses: '',
      classes: '',
      variants: {
        title: 'text-2xl leading-8 font-extrabold text-gray-900 tracking-tight',
        subtitle: 'text-lg leading-6 font-medium text-gray-900',
        error: 'text-red-500',
        badge: 'inline-flex items-center px-3 rounded-full text-xs font-medium leading-4 bg-gray-100 text-gray-800',
        avatar: 'inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-500 overflow-hidden leading-none text-center',
        videoType: 'inline-block px-3 rounded-full text-xs font-semibold leading-5 bg-blue-100 text-blue truncate'
      }
    }
  },
  't-toggle': {
    component: TToggle,
    props: {
      fixedClasses: {
        wrapper: 'relative inline-flex flex-shrink-0 cursor-pointer transition-colors ease-in-out duration-200',
        wrapperChecked: 'relative inline-flex flex-shrink-0 cursor-pointer transition-colors ease-in-out duration-200  border-2 border-transparent focus:ring-2 focus:ring-blue-400 focus:outline-none focus:ring-opacity-50',
        wrapperDisabled: 'relative inline-flex flex-shrink-0 cursor-pointer transition-colors ease-in-out duration-200 opacity-50 cursor-not-allowed',
        wrapperCheckedDisabled: 'relative inline-flex flex-shrink-0 cursor-pointer transition-colors ease-in-out duration-200 opacity-50 cursor-not-allowed border-2 border-transparent focus:ring-2 focus:ring-blue-400 focus:outline-none focus:ring-opacity-50',
        button: 'inline-block absolute transform translate-x-0 transition ease-in-out duration-200',
        buttonChecked: 'inline-block absolute transform translate-x-full transition ease-in-out duration-200',
        checkedPlaceholder: 'inline-block',
        uncheckedPlaceholder: 'inline-block'
      },
      classes: {
        wrapper: 'bg-gray-100 rounded-full border-2 border-transparent focus:ring-2 focus:ring-blue-400 focus:outline-none focus:ring-opacity-50',
        wrapperChecked: 'bg-blue-400 rounded-full',
        wrapperDisabled: 'bg-gray-100 rounded-full border-2 border-transparent focus:ring-2 focus:ring-blue-400 focus:outline-none focus:ring-opacity-50',
        wrapperCheckedDisabled: 'bg-blue-400',
        button: 'h-5 w-5 rounded-full bg-white shadow flex items-center justify-center text-gray-400 text-xs',
        buttonChecked: 'h-5 w-5 rounded-full bg-white shadow flex items-center justify-center text-blue-400 text-xs',
        checkedPlaceholder: 'rounded-full w-5 h-5 flex items-center justify-center text-gray-400 text-xs',
        uncheckedPlaceholder: 'rounded-full w-5 h-5 flex items-center justify-center text-gray-400 text-xs'
      },
      variants: {
        danger: {
          wrapperChecked: 'bg-red-500 rounded-full',
          wrapperCheckedDisabled: 'bg-red-500 rounded-full'
        },
        success: {
          wrapperChecked: 'bg-green-500 rounded-full',
          wrapperCheckedDisabled: 'bg-green-500 rounded-full'
        },
        box: {
          wrapper: 'bg-gray-100 rounded-sm border-2 border-transparent focus:ring-2 focus:ring-blue-400 focus:outline-none focus:ring-opacity-50',
          wrapperChecked: 'bg-blue-400 rounded-sm',
          wrapperCheckedDisabled: 'bg-blue-400 rounded-sm',
          button: 'h-6 w-6 rounded-sm bg-white shadow flex items-center justify-center text-gray-400 text-xs',
          buttonChecked: 'h-6 w-6 rounded-sm  bg-white shadow flex items-center justify-center text-blue-400 text-xs',
          checkedPlaceholder: 'rounded-sm w-6 h-6 flex items-center justify-center text-gray-400 text-xs',
          uncheckedPlaceholder: 'rounded-sm w-6 h-6 flex items-center justify-center text-gray-400 text-xs'
        }
      }
    }
  },
  't-alert': {
    component: TAlert,
    props: {
      fixedClasses: {
        wrapper: 'flex items-center p-4 rounded-lg shadow-sm mb-4',
        body: 'flex-grow',
        close: 'absolute relative flex items-center justify-center ml-4 flex-shrink-0 w-6 h-6 transition duration-100 ease-in-out rounded focus:ring-2 focus:ring-blue-400 focus:outline-none focus:ring-opacity-50',
        closeIcon: 'fill-current h-4 w-4'
      },
      classes: {
        wrapper: 'bg-gradient-to-r from-[#fcd757] to-orange',
        body: 'text-dark',
        close: 'text-white hover:bg-red/60'
      },
      variants: {
        danger: {
          wrapper: 'bg-red-50 border-red-500',
          body: 'text-red-700',
          close: 'text-red-500 hover:bg-red-200'
        },
        success: {
          wrapper: 'bg-green-50 border-green-500',
          body: 'text-green-700',
          close: 'text-green-500 hover:bg-green-200'
        }
      }
    }
  },
  't-card': {
    component: TCard,
    props: {
      fixedClasses: {
        wrapper: 'border rounded shadow-sm ',
        body: 'p-3',
        header: 'border-b p-3 rounded-t',
        footer: 'border-t p-3 rounded-b'
      },
      classes: {
        wrapper: 'bg-white border-gray-100',
        body: '',
        header: 'border-gray-100',
        footer: 'border-gray-100'
      },
      variants: {
        danger: {
          wrapper: 'bg-red-50 text-red-700 border-red-200',
          header: 'border-red-200 text-red-700',
          footer: 'border-red-200 text-red-700'
        }
      }
    }
  },
  't-modal-item': {
    component: TModal,
    props: {
      fixedClasses: {
        overlay: 'z-40 overflow-auto scrolling-touch left-0 top-0 bottom-0 right-0 w-full h-full fixed',
        wrapper: 'relative z-50 top-1/2 ml-auto',
        modal: 'overflow-visible relative rounded',
        body: 'p-3',
        header: 'p-3 rounded-t font-bold text-lg text-dark truncate',
        footer: 'p-3 rounded-b',
        close: 'flex items-center justify-center rounded-full absolute text-dark fill-current right-0 -top-10 h-8 w-8 bg-white transition duration-100 ease-in-out focus:ring-2 focus:ring-blue-400 focus:outline-none focus:ring-opacity-50'
      },
      classes: {
        overlay: '',
        wrapper: 'max-w-lg',
        modal: 'bg-white shadow',
        body: '',
        header: '',
        footer: 'bg-gray-100 flex',
        close: 'text-dark hover:text-dark-400',
        closeIcon: 'fill-current h-5 w-5',
        overlayEnterClass: 'opacity-0',
        overlayEnterActiveClass: 'transition ease-out duration-100',
        overlayEnterToClass: 'opacity-100',
        overlayLeaveClass: 'opacity-100',
        overlayLeaveActiveClass: 'transition ease-in duration-75',
        overlayLeaveToClass: 'opacity-0',
        enterClass: '',
        enterActiveClass: '',
        enterToClass: '',
        leaveClass: '',
        leaveActiveClass: '',
        leaveToClass: ''
      }
    }
  },
  't-modal': {
    component: TModal,
    props: {
      fixedClasses: {
        overlay: 'z-40 overflow-auto scrolling-touch left-0 top-0 bottom-0 right-0 w-full h-full fixed bg-dark-400',
        wrapper: 'relative mx-auto z-50 top-1/2 transform -translate-y-1/2',
        modal: 'overflow-visible relative rounded',
        body: 'p-3',
        header: 'p-3 rounded-t font-bold text-lg text-dark truncate',
        footer: 'p-3 rounded-b',
        close: 'text-dark hover:text-dark-400 flex items-center justify-center rounded-full absolute text-dark fill-current right-0 -top-10 h-8 w-8 bg-white transition duration-100 ease-in-out focus:ring-2 focus:ring-blue-400 focus:outline-none focus:ring-opacity-50',
        closeIcon: 'fill-current h-5 w-5'
      },
      classes: {
        overlay: '',
        wrapper: 'max-w-lg',
        modal: 'bg-white shadow',
        body: '',
        header: '',
        footer: 'bg-gray-100 flex',
        close: 'text-dark hover:text-dark-400',
        closeIcon: 'fill-current h-5 w-5',
        overlayEnterClass: 'opacity-0',
        overlayEnterActiveClass: 'transition ease-out duration-100',
        overlayEnterToClass: 'opacity-100',
        overlayLeaveClass: 'opacity-100',
        overlayLeaveActiveClass: 'transition ease-in duration-75',
        overlayLeaveToClass: 'opacity-0',
        enterClass: '',
        enterActiveClass: '',
        enterToClass: '',
        leaveClass: '',
        leaveActiveClass: '',
        leaveToClass: ''
      },
      variants: {
        screen: {
          modal: 'bg-transparent h-full',
          body: '!p-0 h-[calc(100%-60px)]',
          wrapper: 'w-screen h-screen fixed top-0 left-0 right-0 bottom-0 p-3 bg-dark z-[40] flex flex-col'
        },
        danger: {
          overlay: 'bg-red-100',
          header: 'border-red-50 text-red-700',
          close: 'bg-red-50 text-red-700 hover:bg-red-200 border-red-100 border',
          modal: 'bg-white border border-red-100 shadow-lg',
          footer: 'bg-red-50'
        },
        fit: {
          wrapper: 'w-fit'
        },
        'fit-transparent': {
          modal: 'bg-transparent',
          body: '!p-0 !pt-3',
          wrapper: 'w-fit'
        },
        'xlarge-h-max': {
          wrapper: 'max-w-6xl max-h-vh-full'
        },
        'share-modal': {
          wrapper: 'max-w-6xl max-h-vh-full',
          modal: 'bg-grey-secondary shadow  ml-[120px]'
        },
        large: {
          wrapper: 'max-w-4xl max-h-96'
        },
        'large-h-max': {
          wrapper: 'max-w-4xl max-h-vh-full'
        },
        medium: {
          wrapper: 'max-w-3xl max-h-96'
        },
        'medium-fit': {
          wrapper: 'max-w-3xl max-h-fit'
        },
        'medium-h-max': {
          wrapper: 'max-w-3xl max-h-vh-full'
        },
        small: {
          wrapper: 'max-w-xl'
        },
        grey: {
          modal: 'bg-grey-secondary shadow'
        },
        'grey-large': {
          wrapper: 'max-w-4xl max-h-vh-full mb-6',
          modal: 'bg-grey-secondary shadow'
        }
      }
    }
  },
  't-dialog': {
    component: TDialog,
    props: {
      fixedClasses: {
        overlay: 'flex items-center overflow-auto scrolling-touch left-0 top-0 bottom-0 right-0 w-full h-full fixed z-40 bg-dark-400',
        wrapper: 'relative mx-auto',
        modal: 'overflow-visible relative ',
        close: 'flex items-center justify-center rounded-full absolute right-0 top-0 -m-3 h-8 w-8 transition duration-100 ease-in-out focus:ring-2 focus:ring-blue-400 focus:outline-none focus:ring-opacity-50',
        dialog: 'overflow-visible relative',
        iconWrapper: 'flex flex-shrink-0 h-12 items-center justify-center rounded-full w-12 mx-auto',
        icon: 'w-6 h-6',
        cancelButton: 'block px-4 py-1.5 text-lg font-semibold transition duration-100 ease-in-out rounded-xlg shadow-sm focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed w-40',
        okButton: 'block px-4 py-1.5 text-lg text-white font-semibold transition duration-100 ease-in-out border border-transparent rounded-xlg shadow-sm focus:border-blue-400 focus:ring-2 focus:ring-blue-400 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed w-40 truncate',
        buttons: 'px-4 pt-2 pb-4 flex space-x-4 justify-center bg-gray-100 rounded-b'
      },
      classes: {
        close: 'bg-gray-100 text-gray-600 hover:bg-gray-200',
        closeIcon: 'fill-current h-4 w-4',
        overlay: 'bg-black',
        wrapper: 'z-50 w-96',
        dialog: 'bg-white shadow rounded text-left',
        body: 'p-4 space-y-4',
        buttons: 'bg-gray-100',
        iconWrapper: 'bg-gray-100',
        icon: 'text-gray-500',
        content: 'w-full flex justify-center flex-col',
        titleWrapper: '',
        title: 'text-lg text-dark font-bold text-center',
        textWrapper: 'text-center mt-2.5 w-full',
        text: 'text-dark-500',
        cancelButton: 'text-blue bg-blue-100 border-2 border-blue-300 hover:border-transparent hover:bg-blue-200',
        okButton: 'bg-blue border border-transparent hover:bg-blue-400',
        inputWrapper: 'mt-3 flex items-center space-x-3',
        input: 'block w-full px-3 py-2 transition duration-100 ease-in-out rounded-lg placeholder-dark-400 hover:border-dark-400 focus:border-dark-400 focus:ring focus:ring-focus-input-ring focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed text-dark bg-white border-dark-300 disabled:bg-dark-200 disabled:border-dark-200/0 hover:disabled:border-dark-200/0',
        select: 'block w-full px-3 py-2 text-dark placeholder-gray-400 transition duration-100 ease-in-out bg-white border border-gray-300 rounded shadow-sm focus:border-blue-400 focus:ring-2 focus:ring-blue-400 focus:outline-none focus:ring-opacity-50  disabled:opacity-50 disabled:cursor-not-allowed w-full',
        radioWrapper: 'flex items-center space-x-2',
        radio: 'text-blue-400 transition duration-100 ease-in-out border-gray-300 shadow-sm focus:border-blue-400 focus:ring-2 focus:ring-blue-400 focus:outline-none focus:ring-opacity-50 focus:ring-offset-0  disabled:opacity-50 disabled:cursor-not-allowed',
        radioText: '',
        checkboxWrapper: 'flex items-center space-x-2',
        checkbox: 'text-blue-400 transition duration-100 ease-in-out border-gray-300 rounded shadow-sm focus:border-blue-400 focus:ring-2 focus:ring-blue-400 focus:outline-none focus:ring-opacity-50 focus:ring-offset-0  disabled:opacity-50 disabled:cursor-not-allowed',
        checkboxText: '',
        errorMessage: 'text-red-500 block text-sm',
        busyWrapper: 'absolute bg-opacity-50 bg-white flex h-full items-center justify-center left-0 top-0 w-full',
        busyIcon: 'animate-spin h-6 w-6 fill-current text-gray-500',
        overlayEnterClass: 'opacity-0',
        overlayEnterActiveClass: 'transition ease-out duration-100',
        overlayEnterToClass: 'opacity-100',
        overlayLeaveClass: 'opacity-100',
        overlayLeaveActiveClass: 'transition ease-in duration-75',
        overlayLeaveToClass: 'opacity-0',
        enterClass: '',
        enterActiveClass: '',
        enterToClass: '',
        leaveClass: '',
        leaveActiveClass: '',
        leaveToClass: ''
      },
      variants: {
        danger: {
          okButton: 'bg-red hover:bg-red-500'
        },
        'review-confirm': {
          wrapper: 'max-w-xl',
          cancelButton: 'text-blue bg-blue-100 border-2 border-blue-300 hover:border-transparent hover:bg-blue-200',
          okButton: 'bg-blue border border-transparent hover:bg-blue-400 w-full'
        },
        xl: {
          wrapper: 'max-w-xl',
          cancelButton: 'text-blue bg-blue-100 border-2 border-blue-300 hover:border-transparent hover:bg-blue-200 w-full max-w-xs',
          okButton: 'bg-blue border border-transparent hover:bg-blue-400 w-full max-w-xs'
        },
        md: {
          wrapper: 'max-w-md'
        }
      }
    }
  },
  't-table': {
    component: TTable,
    props: {
      classes: {
        table: 'min-w-full divide-y divide-gray-100 shadow-sm border-gray-200 border',
        thead: '',
        theadTr: '',
        theadTh: 'px-3 py-2 font-semibold text-left bg-gray-100 border-b',
        tbody: 'bg-white divide-y divide-gray-100',
        tr: '',
        td: 'px-3 py-2 whitespace-no-wrap',
        tfoot: '',
        tfootTr: '',
        tfootTd: ''
      },
      variants: {
        thin: {
          td: 'p-1 whitespace-no-wrap text-sm',
          theadTh: 'p-1 font-semibold text-left bg-gray-100 border-b text-sm'
        }
      }
    }
  },
  't-pagination': {
    component: TPagination,
    props: {
      classes: {
        wrapper: 'flex items-center space-x-1 rounded px-1 bg-white',
        element: 'w-8 h-8',
        activeElement: 'w-8 h-8',
        disabledElement: 'w-8 h-8',
        ellipsisElement: 'w-8 h-8 hidden',
        activeButton: 'bg-blue-200 w-full h-full text-sm text-blue rounded hover:bg-blue-600 transition duration-100 ease-in-out focus:outline-none',
        disabledButton: 'text-dark opacity-25 w-full h-full cursor-not-allowed transition duration-100 ease-in-out',
        button: 'text-dark text-sm rounded hover:bg-blue-100 w-full h-full transition duration-100 ease-in-out',
        ellipsis: 'bg-dark w-8 h-8'
      },
      variants: {
        rounded: {
          wrapper: 'bg-white mx-auto text-center flex space-x-2',
          element: 'w-8 h-8 rounded-full',
          activeElement: 'w-8 h-8 rounded-full',
          disabledElement: 'w-8 h-8 rounded-full',
          ellipsisElement: 'w-8 h-8 rounded-full hidden md:inline',
          activeButton: 'border border-blue-400 bg-blue-400 w-full h-full rounded-full text-white hover:bg-blue-600 transition duration-100 ease-in-out focus:ring-2 focus:ring-blue-400 focus:outline-none focus:ring-opacity-50',
          disabledButton: 'border border-gray-200  opacity-25 w-full h-full rounded-full cursor-not-allowed transition duration-100 ease-in-out',
          button: 'border border-gray-200 hover:bg-blue-100 hover:border-blue-100 rounded-full w-full h-full transition duration-100 ease-in-out focus:ring-2 focus:ring-blue-400 focus:outline-none focus:ring-opacity-50',
          ellipsis: 'bg-dark'
        }
      }
    }
  },
  't-dropdown': {
    component: TDropdown,
    props: {
      fixedClasses: {
        button: 'flex items-center text-white block px-4 py-2 transition duration-100 ease-in-out border border-transparent rounded shadow-sm focus:border-blue-400 focus:ring-2 focus:ring-blue-400 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed',
        wrapper: 'inline-flex flex-col',
        dropdownWrapper: 'relative z-20',
        dropdown: 'origin-top-left absolute right-0 w-60 rounded shadow mt-1',
        enterClass: 'opacity-0 scale-95',
        enterActiveClass: 'transition transform ease-out duration-100',
        enterToClass: 'opacity-100 scale-100',
        leaveClass: 'opacity-100 scale-100',
        leaveActiveClass: 'transition transform ease-in duration-75',
        leaveToClass: 'opacity-0 scale-95'
      },
      classes: {
        button: 'font-semibold bg-blue-400 hover:bg-blue-600',
        dropdown: 'bg-white'
      },
      variants: {
        danger: {
          button: 'font-semibold bg-red-500 hover:bg-red-500',
          dropdown: 'bg-red-50'
        },
        tooltip: {
          dropdown: 'origin-center bg-dark'
        }
      }
    }
  },
  't-progress': {
    component: TTag,
    props: {
      fixedClasses: 'w-full bg-dark-300 h-1 [&>*]:transition-all [&>*]:duration-250 [&>*]:ease overflow-hidden rounded-full relative [&>*]:absolute [&>*]:left-0 [&>*]:h-full [&>*]:bg-blue',
      classes: ''
    }
  },
  't-spinner': {
    component: TTag,
    props: {
      tagName: 'svg',
      fixedClasses: 'animate-spin',
      classes: 'h-5 w-5'
    }
  },
  't-container': {
    component: TTag,
    props: {
      fixedClasses: 'container mx-auto',
      classes: ''
    }
  },
  't-grid': {
    component: TTag,
    props: {
      fixedClasses: 'grid',
      classes: ''
    }
  },
  't-heading': {
    component: TTag,
    props: {
      fixedClasses: 'flex items-center text-dark font-bold text-lg border-b border-dark-300',
      classes: 'pb-3 mb-5'
    }
  },
  't-tooltip': {
    component: TTag,
    props: {
      tagName: 'span',
      fixedClasses: 'block w-max pointer-events-none absolute transition duration-100 ease-in-out text-xs text-center px-2 py-1 opacity-0 group-hover/tooltip:opacity-100',
      variants: {
        top: 'bottom-full left-1/2 -translate-x-2/4 bg-white rounded border border-dark-300 text-dark before:content-[\'\'] before:block before:absolute before:top-[calc(100%+1px)] before:left-1/2 before:-translate-y-2/4 before:-translate-x-2/4 before:bg-white before:w-[6px] before:h-[6px] before:rotate-[45deg] before:border-r before:border-b before:border-dark-300',
        bottom: 'top-full left-1/2 -translate-x-2/4 bg-white rounded border border-dark-300 text-dark before:content-[\'\'] before:block before:absolute before:-top-[1px] before:left-1/2 before:-translate-y-2/4 before:-translate-x-2/4 before:bg-white before:w-[6px] before:h-[6px] before:rotate-[45deg] before:border-l before:border-t before:border-dark-300',
        left: 'right-[calc(100%+4px)] bg-white rounded border border-dark-300 text-dark before:content-[\'\'] before:block before:absolute before:top-1/2 before:right-[-7px] before:-translate-y-2/4 before:-translate-x-2/4 before:bg-white before:w-[6px] before:h-[6px] before:rotate-[135deg] before:border-l before:border-t before:border-dark-300',
        right: 'top-[-5px] left-[calc(100%+4px)] bg-white rounded border border-dark-300 text-dark before:content-[\'\'] before:block before:absolute before:top-1/2 before:left-0 before:-translate-y-2/4 before:-translate-x-2/4 before:bg-white before:w-[6px] before:h-[6px] before:rotate-[135deg] before:border-r before:border-b before:border-dark-300',
        'bottom-left': 'top-full right-0 bg-white rounded border border-dark-300 text-dark before:content-[\'\'] before:block before:absolute before:-top-[1px] before:left-[calc(100%-18px)] before:-translate-y-2/4 before:bg-white before:w-[6px] before:h-[6px] before:rotate-[45deg] before:border-l before:border-t before:border-dark-300',
        'top-dark': 'bottom-full left-1/2 -translate-x-2/4 bg-dark rounded border border-dark text-white before:content-[\'\'] before:block before:absolute before:top-[calc(100%+1px)] before:left-1/2 before:-translate-y-2/4 before:-translate-x-2/4 before:bg-dark before:w-[6px] before:h-[6px] before:rotate-[45deg] before:border-r before:border-b before:border-dark',
        'bottom-dark': 'top-full left-1/2 -translate-x-2/4 bg-dark rounded border border-dark text-white before:content-[\'\'] before:block before:absolute before:-top-[1px] before:left-1/2 before:-translate-y-2/4 before:-translate-x-2/4 before:bg-dark before:w-[6px] before:h-[6px] before:rotate-[45deg] before:border-l before:border-t before:border-dark',
        'bottom-left-dark': 'top-full right-[calc(100%-24px)] bg-dark rounded border border-dark text-white before:content-[\'\'] before:block before:absolute before:-top-[1px] before:left-[calc(100%-18px)] before:-translate-y-2/4 before:bg-dark before:w-[6px] before:h-[6px] before:rotate-[45deg] before:border-l before:border-t before:border-dark'
      }
    }
  }
};
